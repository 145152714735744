import React, {Component} from "react";
import axios from "axios";
import "./css/theme.css";
import DigitalClock from "./DigitalClock.jsx";

// Uncomment the next line for layout debugging
// import "./css/debug.css";


class App extends Component {

    state = {
        uptime: "",
    }

    componentDidMount(prevProps, prevState) {
        this.fetchMisc();
    }

    fetchMisc() {

        axios.get(`/misc`)
            .then(res => {
                let data = res.data;
                const uptime = data.uptime;
                this.setState({
                    uptime: uptime,
                });
            })
    };

    constructor(props) {
        super(props);
    }

    render() {
        return this.getElement();
    }

    getElement() {
        return <><>
            {/*<Hero*/}
            {/*  title={"Proto Zero One"}*/}
            {/*/>*/}

            <div className="column is-9">

                {/*<section className="hero is-info welcome is-small">*/}
                {/*  <div className="hero-body">*/}

                {/*  </div>*/}
                {/*</section>*/}
                <section className="info-tiles">
                    <div className="tile is-ancestor has-text-centered">
                        <div className="tile is-parent">
                            <article className="tile is-child box">
                                <p className="title"><DigitalClock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Stockholm'} /></p>
                                <p className="subtitle">Current Time</p>
                            </article>
                        </div>
                        <div className="tile is-parent">
                            <article className="tile is-child box">
                                <p className="title">59k</p>
                                <p className="subtitle">Products</p>
                            </article>
                        </div>
                        <div className="tile is-parent">
                            <article className="tile is-child box">
                                <p className="title">3.4k</p>
                                <p className="subtitle">Open Orders</p>
                            </article>
                        </div>
                        <div className="tile is-parent">
                            <article className="tile is-child box">
                                <p className="title">19</p>
                                <p className="subtitle">Exceptions</p>
                            </article>
                        </div>
                    </div>
                </section>
                <div className="columns">
                    <div className="column is-6">
                        <div className="card events-card">
                            <header className="card-header">
                                <p className="card-header-title">
                                    Events
                                </p>
                                <a href="#" className="card-header-icon" aria-label="more options">
                  <span className="icon">
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                                </a>
                            </header>
                            <div className="card-table">
                                <div className="content">
                                    <table className="table is-fullwidth is-striped">
                                        <tbody>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        <tr>
                                            <td width="5%"><i className="fa fa-bell-o"></i></td>
                                            <td>Lorum ipsum dolem aire</td>
                                            <td className="level-right"><a className="button is-small is-primary"
                                                                           href="#">Action</a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <footer className="card-footer">
                                <a href="#" className="card-footer-item">View All</a>
                            </footer>
                        </div>
                    </div>
                    <div className="column is-6">
                        <div className="card">
                            <header className="card-header">
                                <p className="card-header-title">
                                    Inventory Search
                                </p>
                                <a href="#" className="card-header-icon" aria-label="more options">
                  <span className="icon">
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                                </a>
                            </header>
                            <div className="card-content">
                                <div className="content">

                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <header className="card-header">
                                <p className="card-header-title">
                                    User Search
                                </p>
                                <a href="#" className="card-header-icon" aria-label="more options">
                  <span className="icon">
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                                </a>
                            </header>
                            <div className="card-content">
                                <div className="content">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
        </>;
    }
}

export default App;
