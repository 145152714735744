import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";
import {useForm} from "react-hook-form";

//inspo: https://www.geeksforgeeks.org/bulma-form/
export default function RootPage() {

    const [fetchedData, setFetchedData] = useState({});
    const [isThankYou, setIsThankYou] = useState(false);
    const {register, handleSubmit, watch, reset, formState: {errors}} = useForm({mode: "onTouched"});
    const [title, setTitle] = useState("[Page Title]");

    console.log(errors);
    console.log(fetchedData);

    const onSubmit = data => {
        console.log(data);
        console.dir(data.name)
        let headers = {
            'Content-Type': 'application/json'
        };
        axios.post('/rootpageform', {
            name: data.name,
            email: data.email,
            message: data.message
        }, {headers})
            .then(response => {
                console.log("OK");
                console.log(response);
                setIsThankYou(true);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        axios.get(`/reqinfo`).then(res => {
            document.title = `[${res.data.hostname}]`;
        })
    }, [setFetchedData]);

    useEffect(fetch, [setFetchedData]);

    function fetch() {
        axios.get(`/reqinfo`)
            .then(res => {
                console.log(res.data);
                setFetchedData(res.data)
            });
    }


    function theForm() {
        return (
            <>
                <div className="container box p-6 has-background-light">
                    <>
                        <h1 className="title has-text-centered has-text-primary">{fetchedData.hostname}
                        </h1>
                        <h2 className="subtitle has-text-centered">Contact Form</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="field">
                                <label className="label">Your Name</label>
                                <div className="control">
                                    <input className="input" type="text"
                                           placeholder="" {...register("name", {required: "Required"})}/>
                                </div>
                                <p className="label help is-danger">{errors.name?.message}</p>
                            </div>

                            <div className="field">
                                <label className="label">Your Email</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input" type="email"
                                           placeholder="" {...register("email", {required: "Required"})}/>
                                    <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                                </div>
                                <p className="label help is-danger">{errors.email?.message}</p>
                            </div>

                            <div className="field">
                                <label className="label">
                                    Your message
                                </label>
                                <div className="control">
                            <textarea className="textarea" rows="3" maxLength="300"
                                      placeholder="" {...register("message", {required: "Required"})}/>
                                </div>
                                <p className="label help is-danger">{errors.message?.message}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="control">
                                    <button className="button is-primary">Submit</button>
                                </div>
                                <div className="control">
                                    <button className="button is-link is-light" onClick={() => reset()}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </>
                </div>
            </>
        );
    }

    const goBack = () => {
        reset();
        setIsThankYou(false);
    }

    function theThankYouPage() {
        return (
            <div className="container box p-6 has-background-light">
                <div className="container">
                    <h1 className="title has-text-centered has-text-primary">{fetchedData.hostname}
                    </h1>
                    <div className="container mt-6">
                        <p className="subtitle has-text-centered">Your message has been received. Thank you.</p>
                    </div>
                    <div className="container mt-6">
                        <p className="has-text-centered"><a onClick={goBack} className="is-link">Back</a></p>
                    </div>
                </div>
            </div>
        );
    }

    if (!isThankYou) {
        return theForm()
    } else {
        return theThankYouPage();
    }
}