import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AnotherApp from './AnotherApp';
import RootPage from "./RootPage";

const rootElement = ReactDOM.createRoot(document.getElementById("root"));

rootElement.render(
    <BrowserRouter>
        {/*NOTE: New React routes need to be added both here and in the index.js of the Express server*/}
        <Routes>
            <Route path="/" element={<RootPage/>}/>
            <Route path="/app" element={<App/>}/>
            <Route path="/anotherapp" element={<AnotherApp/>}/>

            <Route path="*" element={
                <main style={{padding: '1rem'}}>
                    <p>There's nothing here!</p>
                </main>
            }
            />
        </Routes>
    </BrowserRouter>
);

